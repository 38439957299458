

import Landing from "../../pages/lands/Landing2";
import Login from '../../pages/general/logins/Login2';
import Register from '../../pages/general/registers/register2';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaBtc } from "react-icons/fa";
import Forgot from '../../pages/general/forgots/Forgot2'


const BrandName = 'coinX';

const changeTitle = () => {
    document.title = "coinX";
  }

const MenuTerms = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);

    return (
        <div className={`fixed ${isScrolled ? ' bg-white/80 shadow ' : 'bg-king'} backdrop-blur-sm top-0 w-full transition duration-300 ease-in-out z-10`}>
            <div className='bod min-h-[72px] flex flex-row items-center justify-between'>
                <span className={`text-3xl font-semibold ${isScrolled ? 'text-king' : 'text-white'} flex flex-row items-center cursor-pointer`}  onClick={() => navigate('/')}>
                    <Logo />
                </span>
                <div className='flex flex-row items-center space-x-5'>
                    <button className='text-blue-600 hover:text-blue-700 font-semibold px-4' onClick={() => navigate('/login')}>
                        Log in
                    </button>
                    <button className='btn3 py-2' onClick={() => navigate('/register')} >
                        Sign up
                    </button>
                </div>
            </div>
        </div>
    )
}


const Logo = ({color}) => {
    const navigate = useNavigate()
    return (
        <div className={`text-white flex flex-row font-semibold text-4xl cursor-pointer ${color == 'undefined' ? 'text-white' : '!text-blue-600'} `} onClick={() => navigate('/')}>
            <span>coin</span>
            <span className="font-bold">X</span>
        </div>
    )
}


export {Landing, Login, Register, MenuTerms, changeTitle, Logo, BrandName, Forgot}