import React, { useState, useEffect, useRef } from 'react';
import { FaSquare } from "react-icons/fa";
import { FcPositiveDynamic } from "react-icons/fc";
import { FcElectronics } from "react-icons/fc";
import { FcGlobe } from "react-icons/fc";
import { FcGenealogy } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcGraduationCap } from "react-icons/fc";
import { motion } from "framer-motion";
import { Link, useNavigate } from 'react-router-dom';
import { postData } from '../../lib/apiHandler';
import { FaBtc } from "react-icons/fa";
import { Logo } from '../../App';



function LoadingPage() {
    const mainRef = useRef()
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
        const element = mainRef.current;
        var top = element.getBoundingClientRect().top
        setIsScrolled(top < 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    /////////// LINE CIRCULE //////////
    const [direction, setDirection] = useState(1); // 1 for right, -1 for left
    const lineRef1 = useRef()
    const lineRef2 = useRef()
    const [x, setX] = useState(0);
    const [y, setY] = useState(-67464);

    // useEffect(() => {
    //     console.log(y)
    // }, [y])

    useEffect(() => {
        const interval = setInterval(() => {
            setX((prevX) => {
                if (direction === 1){
                    if (prevX > 0) {
                        setDirection(-1)
                    }
                } else {
                    if (prevX < -(144*coins.length) + lineRef1.current.clientWidth) {
                        setDirection(1)
                    }
                }
                return prevX + direction * 5
            });
        }, 50);
        return () => clearInterval(interval);
    }, [direction]);


    const images = require.context('../../../public/icons', false, /\.png$/);
    const coins = images.keys();

    useEffect(() => {
        setY(-(144*coins.length) + lineRef2.current.clientWidth)
    }, [lineRef2])

    useEffect(() => {
        const interval = setInterval(() => {
            setY((prevY) => {
                if (direction === -1){
                    if (prevY > 0) {
                        setDirection(1)
                    }
                } else {
                    if (prevY < -(144*coins.length) + lineRef1.current.clientWidth) {
                        setDirection(-1)
                    }
                }
                return prevY + -direction * 5
            });
        }, 50);
        return () => clearInterval(interval);
    }, [direction]);


    const [sel, setSel] = useState(0)
    const [sent, setSent] = useState(false)

    const sendMsg = async (e) => {
        e.preventDefault();
        var formObj = formDataToObject(new FormData(e.target));

        const result = await postData('/contact', {
            ...formObj,
            sel: sel
        })
        setSent(true)
    }


    const selBut = [
        {
            h1: 'Wallet-as-a-Service',
            text: 'Wallet infrastructure APIs and SDKs'
        },
        {
            h1: 'Custody',
            text: 'Custodial / MPC / Smart Wallets'
        },
        {
            h1: 'Partnership / PR /Others',
            text: 'All other inquiries'
        },
    ]


    const formDataToObject = (formData) => {
        let object = {};
        formData.forEach((value, key) => {
            object[key] = value;
        });
        return object;
    };

    const contactRef = useRef();
    const scrollToRef = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };
      

    // https://BsmartSolutions.ltd
  return (
    <div className="flex flex-col min-h-screen">

        {/* MENU */}
        <div className={`fixed ${isScrolled ? ' bg-white/80 shadow ' : 'bg-king'} backdrop-blur-sm top-0 w-full transition duration-300 ease-in-out z-10`}>
            <div className='bod min-h-[72px] flex flex-row items-center justify-between'>
                <span className={`text-3xl font-semibold ${isScrolled ? 'text-king' : 'text-white'} flex flex-row items-center`}>
                    <Logo color={isScrolled ? '!text-blue-600' : 'text-white'} />
                </span>
                <div className='flex flex-row items-center space-x-5'>
                    <button className='text-blue-600 hover:text-blue-700 font-semibold px-4' onClick={() => navigate('/login')}>
                        Log in
                    </button>
                    <button className='btn3 py-2' onClick={scrollToRef}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>

        {/* HEADER */}
        <div className="bg-king">
            <div className='bod py-[20px] pt-[60px] pt-[90px] md:pb-[20px]'>
                <div className='flex flex-col md:flex-row items-center'>
                    <div className='w-full md:w-[60%] min-w-[400px] space-y-6'>
                        <h1 className='text-white text-[50px] font-bold leading-[65px]'>Powering Your <br></br> Crypto Ambitions</h1>
                        <p className='text-[22px] text-gray-400'>
                            Save time, reduce cost and offer a powerful and secure wallet infrastructure effortlessly.
                        </p>
                        <div className='py-2'>
                            <button className='btn3 px-[25px] py-2.5 text-xl font-semibold'  onClick={() => navigate('/register')}>
                                Sign up
                            </button>
                        </div>
                    </div>
                    <div className='w-full md:w-[40%] min-w-[350px] min-h-[450px]'>
                        <img src='/big-slogen.webp' className='w-full' />
                    </div>
                </div>
            </div>
        </div>

        <div ref={mainRef}>
            {/* SEC1 */}
            <div>
                <div className='bod py-[120px] space-y-10'>
                    <div>
                        <h1 className='text-king text-[32px] font-bold'>Institution's Trusted Digital Asset Custody Provider</h1>
                        <span className='text-gray-400 text-[22px] font-semibold'>Built for Institutions, Designed for Security and Operational Efficiency</span>
                    </div>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        <Box top='500+' bot='Institutional Clients' />
                        <Box top='1M+' bot='Registered Users' />
                        <Box top='100B+' bot='Transaction Volume' />
                        <Box top='6 Years' bot='Continuous Service' />
                    </div>
                </div>
            </div>
            
            {/* SEC2 */}
            <div className="bg-[#f7f9fc]">
                <div className='bod py-[120px] flex flex-col space-y-10'>
                    <div>
                        <h1 className='text-king text-[32px] font-bold'>The Gold Standard for Digital Asset Custody</h1>
                        <span className='text-gray-400 text-[22px] font-semibold'>Why Choose coinX?</span>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <Box2 title="Top Security Standards" icon={<FcPositiveDynamic className='text-4xl'/> } list={['Multi-layer defense for complete protection', 'Zero security incidents ever']} />
                        <Box2 title="Unparalleled Access" icon={<FcElectronics className='text-4xl'/> } list={['Widest coverage of 1,800+ tokens and 70+ chains', 'Quick support for new tokens and chains', 'Extensive APIs and SDKs']} />
                        <Box2 title="Next-Gen Holistic Solutions" icon={<FcGlobe className='text-4xl'/> } list={['From Full Custody, Self-Custody to Wallet-as-a-Service', 'Off-exchange settlement network', 'DeFi investment tools for frictionless access']} />
                        <Box2 title="Customizable, Easy-to-Use" icon={<FcGenealogy className='text-4xl'/> } list={['Role-based access controls', 'Intuitive web interface', 'Mobile app to manage assets ']} />
                        <Box2 title="Compliant and Licensed" icon={<FcManager className='text-4xl'/> } list={['SOC 2 Type 1 and Type 2-certified', 'Licensed in 4 jurisdictions']} />
                        <Box2 title="First-Class Support" icon={<FcGraduationCap className='text-4xl'/> } list={['Dedicated customer service managers', '24/7 technical assistance']} />
                    </div>
                </div>
            </div>

            <div className='py-[100px] space-y-10 bg-[#28304d]'>
                <div className='bod'>
                    <h1 className='text-white text-[36px] font-bold'>Widest Coverage In The Industry</h1>
                    <span className='text-gray-400 text-[22px] font-semibold'>coinX Supports 1,800+ Tokens Across 70+ Chains, and NFTs</span>
                </div>
                <motion.div 
                    className='overflow-x-hidden space-y-7'
                    initial={{ opacity: 0 }} 
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >

  
                    <motion.div 
                        ref={lineRef1}
                        className='flex flex-row [&>div]:px-8 '
                        animate={{ x: x }}
                        transition={{ type: "spring", damping: 10, stiffness: 100 }}
                    >
                        {
                            coins.map((coin, c) => (
                                <div key={c} className=''>
                                    <div className='w-[80px] h-[80px] flex items-center justify-center'>
                                        <img src={`/icons${coin.substring(1) }`} className='' />
                                    </div>
                                </div>
                            ))
                        }
                    </motion.div>
                    <motion.div 
                        ref={lineRef2}
                        className='flex flex-row [&>div]:px-8'
                        initial={{x: -67464}}
                        animate={{  x: y }}
                        transition={{ type: "spring", damping: 10, stiffness: 100 }}
                    >
                        {
                            coins.map((coin, c) => (
                                <div key={c} className=''>
                                    <div className='w-[80px] h-[80px] flex items-center justify-center'>
                                        <img src={`/icons${coin.substring(1) }`} className='' />
                                    </div>
                                </div>
                            ))
                        }
                    </motion.div>

                </motion.div>
            </div>


            <div className="bg-[#f7f9fc] py-[80px]">
                <div className='bod py-[20px] md:py-[50px]'>
                    <div className='flex flex-col md:flex-row items-center'>
                        <div className='w-full md:w-[50%] space-y-3'>
                        <h1 className='text-king text-[32px] font-bold'>Start your crypto journey</h1>
                        <span className='text-gray-400 text-[22px] font-semibold'>Bcoin makes it easy to get started. Sign up today to buy and sell 200+ cryptocurrencies.</span>
                            <div className='py-2'>
                                <button className='btn3 px-[25px] py-2.5 text-xl font-semibold' onClick={() => navigate('/register')}>
                                    Get started with coinX
                                </button>
                            </div>
                        </div>
                        <div className='w-full md:w-[50%]'>
                            <img className='w-[80%]' src='https://assets-cms.kraken.com/images/51n36hrp/facade/89bfa16bbcd0bf6343856c9877360ec7f14f5a1a-1040x868.png?w=2048&fit=min&fm=webp' />
                        </div>
                    </div>
                </div>
            </div>



            <div className="bg-white py-[20px]" ref={contactRef}>
                <div className='bod py-[20px] md:py-[50px]'>
                    <div className='flex flex-col'>

                        {
                            sent ? (
                                <div className='flex flex-col space-y-6 items-center justify-center my-12'>
                                    <img src={`/icons2/succ.png`} className='w-[200px]' />
                                    <h1 className='text-king text-[32px] font-bold'>Your form was Successfully submitted!</h1>
                                </div>
                            ) : (
                                <>
                                    <h1 className='text-king text-[32px] font-bold'>Contact Us</h1>
                                    <div className='flex flex-row text-gray-400 text-[22px] font-semibold space-x-2'>
                                        <span className=''>
                                            <span>If you are an existing customer, </span> 
                                            <Link to={'/login'} className='text-blue-600 hover:underline'> click here</Link>
                                        </span>
                                    </div>


                                    <form className='flex my-10 w-full items-center justify-center' onSubmit={sendMsg} autoComplete='off'>
                                        <div className='flex flex-col w-full max-w-[800px] space-y-6'>

                                            <div className='flex flex-row space-x-3'>
                                                <div className='w-full'>
                                                    <span className='text-gray-600'>
                                                        Full name
                                                    </span>
                                                    <input className='inp inp2' name='name' type='text' required />
                                                </div>
                                                <div className='w-full'>
                                                    <span className='text-gray-600'>
                                                        Email
                                                    </span>
                                                    <input className='inp inp2' name='email' type='email' required />
                                                </div>
                                            </div> 

                                            <div className='flex flex-row justify-between space-x-5'>
                                                {
                                                    selBut.map((item, i) => (
                                                        <div key={i} onClick={() => setSel(i)} className={`con w-full cursor-pointer ${sel === i ? 'bg-blue-500 text-white [&>span]:text-gray-200' : '[&>span]:text-gray-700'}`}>
                                                            <h2 className='text-lg font-semibold'>{item.h1}</h2>
                                                            <span className=''>{item.text}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className='space-y-1'>
                                                <span className='flex flex-row text-gray-400 text-[22px] font-semibold'>Let us know how we can help!</span>
                                                <textarea name='message' className='inp inp2 h-[250px] text-[18px]' placeholder='Tell us your needs, and we’ll tailor our support to fit.' required>

                                                </textarea>
                                            </div>

                                            <div className='flex flex-row items-end justify-end'>
                                                <button type='submit' className='btn btn3 text-white px-7 text-[22px] h-[47px]'>Send</button>
                                            </div>
                                        </div>
                                    </form>
                                
                                </>
                            )
                        }


                    </div>
                </div>
            </div>




            <div className="bg-[#28304d]">
                <div className='bod py-[50px] sm:py-[80px] flex flex-col sm:flex-row  flex justify-between items-start sm:items-center space-y-4 sm:space-y-0 text-white'>

                    <div className={`text-3xl font-bold flex flex-row items-center`}>
                        <Logo />
                    </div>

                    <div className='flex flex-col items-end'>
                        <span className='font-semibold'>Copyright ©2024 CoinX. All rights reserved.</span>
                        <div className='flex flex-row space-x-2'>
                            <Link to='/terms' className='font-semibold hover:underline'>Terms of service</Link>
                            <span>/</span>
                            <Link to='/policy' className='font-semibold hover:underline'>Privacy policy </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default LoadingPage;







function Box2({title, list, icon}) {
    return (
        <div className='con flex flex-col space-y-4 p-[30px]'>
            {icon}
            <h2 className='text-king text-[22px] font-semibold'>{title}</h2>
            <div className='text-[18px] space-y-3'>
                {
                    list.map((item, i) => (
                        <div key={i} className='flex flex-row items-start space-x-3'>
                            <div className='h-[27px] flex items-center justify-center'>
                                <FaSquare className='text-blue-100 w-[12px]' />
                            </div> 
                            <span className='text-gray-500'>{item}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}


function Box({top, bot}) {
    return (
        <div className='flex flex-col items-center'>
            <span className='text-amber-400 text-[50px] font-bold'>{top}</span>
            <span className='text-king text-[20px] font-semibold'>{bot}</span>
        </div>
    )
}
